<template>
  <div class="strength">
    <div class="header">
      <div class="w">
        <div class="left">ABOUT US</div>
        <div class="right">关于我们</div>
      </div>
    </div>
    <div class="header_title w">
      <h2 class="current">公司简介</h2>
      <div class="border"></div>
      <h2>招聘信息</h2>
    </div>
    <div class="strength_cont">
      <div class="desc_company w">
        <img src="../assets/img/3_1.jpg" alt="公司简介">
        <div class="desc_company_box">
          <h3>海南掌上能量传媒有限公司</h3>
          <p class="intro">速绿充电成立于2020年1月，总部坐落于北京，是国内领先的共享充电平台，更是传统充电行业的颠覆者，让绿色的生活方式和快速的充电体验结合成一种习惯。</p>
          <ul>
            <li>
              <h4>团队介绍</h4>
              <p>专业的研发及技术团队，现有团队3000余人，其中核心技术成员300余人，自主研发，自主生产，建有完善的技术管理与质量保证体系。</p>
            </li>
            <li class="two">
              <h4>公司愿景</h4>
              <p>网点遍布全国，以智能充电宝租赁终端设备实现手机随时随地免押借用充电宝，大幅减少能源浪费，让你的手机永不断电！</p>
            </li>
            <li>
              <h4>政策红利</h4>
              <p>借国家海南自贸港的政策东风和注册制的东风，公司快速发展寻求在国内上市，并在海外大力拓展市场。</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="desc_technology w">
        <h3>国家高新技术企业，发明专利，实用新型，外观专利</h3>
        <img src="../assets/img/3_2.jpg" alt="国家高新技术企业">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    };
  },
  components: {},
  mounted () {},
  methods: {}
}
</script>

<style lang='scss' scoped>
  h1, h2, h3, h4, h5, p, ul, li {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  ul {
    list-style: none;
  }
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .strength {
    width: 100%;
  }
  .header {
    background: #2EB94F;
    height: 80px;
    line-height: 80px;
    text-align: left;
  }
  .header .w div {
    display: inline-block;
    vertical-align: middle;
  }
  .header .left {
    font-size: 36px;
    color: #e8e8e8;
    font-weight: 700;
  }
  .header .left::after {
    content: "";
    display: inline-block;
    height: 20px;
    margin: 0 10px 0 25px;
    border-right: 1px solid #fff;
  }
  .header .right {
    font-size: 28px;
    color: #fff;
  }
  .header_title {
    height: 100px;
    padding-top: 50px;
  }
  .header_title h2 {
    display: inline-block;
    height: 48px;
    padding: 0 15px;
    -webkit-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    border-radius: 24px;
    line-height: 48px;
    font-size: 30px;
    color: #1b1b1b;
  }
  .header_title .border {
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 46px;
    margin: -10px 96px 0;
    background-color: #2fb15a;
  }
  .header_title h2.current {
    background-color: #2fb15a;
    color: #fff;
  }
  .strength_cont {
    position: relative;
  }
  .strength_cont::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 50%;
    height: 615px;
    background-color: #f6f6f6;
  }
  .desc_company {
    position: relative;
    height: 615px;
    text-align: left;
  }
  .desc_company img {
    height: 615px;
  }
  .desc_company_box {
    position: absolute;
    top: 0;
    right: 0;
    width: 515px;
    padding-top: 128px;
    overflow: hidden;
  }
  .desc_company_box h3 {
    height: 68px;
    font-size: 30px;
    color: #2fb15a;
  }
  .desc_company_box .intro {
    line-height: 24px;
    font-size: 18px;
    color: #9a9a9a;
  }
  .desc_company_box ul {
    width: 520px;
    height: 232px;
    margin-top: 30px;
  }
  .desc_company_box li {
    float: left;
    width: 140px;
    padding: 0 23px;
  }
  .desc_company_box li.two {
    border-left: 1px solid #2fb15a;
    border-right: 1px solid #2fb15a;
  }
  .desc_company_box li:first-child {
    padding-left: 0;
  }
  .desc_company_box li:last-child {
    width: 120px;
  }
  .desc_company_box li h4 {
    height: 40px;
    font-size: 20px;
    color: #1b1b1b;
    text-align: center;
    font-weight: 700;
  }
  .desc_company_box li p {
    line-height: 24px;
    font-size: 16px;
    color: #1b1b1b;
    text-align: justify;
  }
  .desc_technology {
    text-align: center;
    margin-bottom: 230px;
  }
  .desc_technology h3 {
    height: 125px;
    margin-top: 200px;
    font-size: 30px;
    color: #2fb15a;
    font-weight: 700;
  }
</style>